<template>
	<div>
		<div>
			<div class="input-section" v-if="buyInOfferSelected">
				<label for="fromPlayerCashCOC">{{ languageStrings.cashReceived }}</label>
				<div id="fromPlayerCashCOC">
					<span v-if="restrictedNeedsMoreInfo">
						{{ languageStrings.buyInLimited }}:
						{{
							systemCurrencyTool.formatCurrency(
								webPayOptions.taxAmlRestrictions.buyInThresholdCOC,
								systemCurrencyTool.displayType.minorOrFull
							)
						}}
					</span>
					<span>
						<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
							systemCurrencyTool.currencyInfo.symbol
						}}</span>
						<input
							type="number"
							name="displayCOC"
							v-model="fromPlayerCashCOC"
							:placeholder="1"
							autocomplete="off"
							id="currency"
							@blur="updateFromPlayerCashCoC()"
						/>
						<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
							systemCurrencyTool.currencyInfo.symbol
						}}</span>
					</span>
					<span>
						<span class="currancy-symbol">{{ systemCurrencyTool.currencyInfo.symbol }}</span>
						<input
							type="range"
							name="displayCOC"
							:min="buyInOffer.minimumCOC"
							:max="
								restrictedNeedsMoreInfo
									? webPayOptions.taxAmlRestrictions.buyInThresholdCOC
									: buyInOffer.maximumCOC
							"
							v-model="fromPlayerCashCOC"
							autocomplete="off"
						/>
						<span class="currancy-symbol"
							>{{ systemCurrencyTool.currencyInfo.symbol }}{{ systemCurrencyTool.currencyInfo.symbol
							}}{{ systemCurrencyTool.currencyInfo.symbol }}</span
						>
					</span>
				</div>
				<div>
					<div class="row">
						<div class="column">
							<button
						class="btn add-in-btn encore-price-button"
						@click="addMoneyToInput(10)"
						:disabled="
							restrictedNeedsMoreInfo &&
							fromPlayerCashCOC + 10 > webPayOptions.taxAmlRestrictions.buyInThresholdCOC
						"
					>
								<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
								10
								<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
							</button>
						</div>
						<div class="column">
							<button
						class="btn add-in-btn encore-price-button"
						@click="addMoneyToInput(25)"
						:disabled="
							restrictedNeedsMoreInfo &&
							fromPlayerCashCOC + 25 > webPayOptions.taxAmlRestrictions.buyInThresholdCOC
						"
					>
								<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
								25
								<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
							</button>
						</div>
					</div>
					<div class="row">
						<div class="column">
							<button
						class="btn add-in-btn encore-price-button"
						@click="addMoneyToInput(50)"
						:disabled="
							restrictedNeedsMoreInfo &&
							fromPlayerCashCOC + 50 > webPayOptions.taxAmlRestrictions.buyInThresholdCOC
						"
					>
								<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
								50
								<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
							</button>
						</div>
						<div class="column">
							<button
						class="btn add-in-btn encore-price-button"
						@click="addMoneyToInput(100)"
						:disabled="
							restrictedNeedsMoreInfo &&
							fromPlayerCashCOC + 100 > webPayOptions.taxAmlRestrictions.buyInThresholdCOC
						"
					>
								<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
								100
								<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
									systemCurrencyTool.currencyInfo.symbol
								}}</span>
							</button>
						</div>
					</div>
				</div>
				<div id="select-container">
					<span>Select Provider</span>
					<select title="Select Provider" v-model="provider" id="paymentOptions">
						<option v-for="(item, index) in paymentOptions" :key="index" :value="item">
							{{ item }}
						</option>
					</select>
				</div>
				<NuveiCashIn
					v-if="webPayOptions?.nuvei && provider === 'nuvei'"
					:playerState="playerState"
					:webPayOptions="webPayOptions"
					:systemCurrencyTool="systemCurrencyTool"
					:buyInOffer="buyInOffer"
					:languageErrorStrings="languageErrorStrings"
					:languageStrings="languageStrings"
					:fromPlayerCashCOC="fromPlayerCashCOC"
					:provider="provider"
				/>
				<ConektaCashIn
					v-if="webPayOptions?.conekta && provider === 'conekta'"
					:playerState="playerState"
					:webPayOptions="webPayOptions"
					:systemCurrencyTool="systemCurrencyTool"
					:buyInOffer="buyInOffer"
					:languageErrorStrings="languageErrorStrings"
					:languageStrings="languageStrings"
					:fromPlayerCashCOC="fromPlayerCashCOC"
					:provider="provider"
				/>
				<!-- *** DirectaCashIn component has not been built yet *** -->
				<!-- *** DirectaCashIn component is just a copy/paste of ConektaCashIn *** -->
				<!-- <DirectaCashIn
					v-if="webPayOptions.directa && provider === 'directa'"
					:playerState="playerState"
					:webPayOptions="webPayOptions"
					:systemCurrencyTool="systemCurrencyTool"
					:buyInOffer="buyInOffer"
					:languageErrorStrings="languageErrorStrings"
					:languageStrings="languageStrings"
					:fromPlayerCashCOC="fromPlayerCashCOC"
					:provider="provider"
				/> -->
			</div>
		</div>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import NuveiCashIn from "@/components/NuveiCashIn";
import ConektaCashIn from "@/components/ConektaCashIn";
// import DirectaCashIn from "@/components/DirectaCashIn";
import { onBeforeUnmount } from "vue";
import router from "@/router";

export default {
	name: "CommitBuyInOffer",
	components: {
		NuveiCashIn,
		ConektaCashIn,
		// DirectaCashIn,
	},
	props: {
		buyInOfferSelected: Boolean,
		playerState: Object,
		buyInOffer: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		webPayOptions: Object,
		isMobile: Boolean,
		restrictedNeedsMoreInfo: Boolean,
	},
	data() {
		return {
			activeSession: this.session.get(),
			status: Object.assign({}, this.globalStatus),
			currencyInfo: this.playerState.currencyInfo,
			systemCurrencyInfo: this.systemCurrencyTool.currencyInfo,
			fromPlayerCashCOC: this.buyInOffer.minimumCOC,
			displayCOC: 0,
			regularAU: 0,
			buyIn: {
				cashierBankId: 0,
				playerUserId: "",
				accountAccessCode: 0,
				buyInOfferId: 0,
				fromPlayerCashCOC: 0,
			},
			transactionReport: {},
			buyInTransactionDetails: {},
			currencyInfo: this.playerState.currencyInfo,
			systemCurrencyInfo: this.systemCurrencyTool.currencyInfo,
			systemMinorOrFull: this.systemCurrencyTool.displayType.minorOrFull,
			systemFull: this.systemCurrencyTool.displayType.full,
			serverBusy: false,
			busyText: "",
			transaction: {},
			nuveiResult: {},
			playerFunds: {},
			transactionResult: {},
			paymentOptions: [],
			provider: "",
		};
	},
	watch: {
		buyInOfferSelected() {
			if (!this.buyInOfferSelected) this.fromPlayerCashCOC = null;
		},
		fromPlayerCashCOC() {
			this.fromPlayerCashCOC = Number(this.fromPlayerCashCOC);
		},
	},
	created() {
		this.getPaymentOptions();
	},
	methods: {
		getPaymentOptions() {
			let webPayKeys = Object.keys(this.webPayOptions);
			webPayKeys.forEach((key) => {
				if ((key === "nuvei" || key === "conekta" || key === "directa") && this.webPayOptions[key]?.allowBuyIn)
					this.paymentOptions.push(key);
			});
		},
		addMoneyToInput(amount) {
			let fromPlayer = Number(this.fromPlayerCashCOC) + amount;
			if (fromPlayer > this.buyInOffer.maximumCOC) return;
			this.fromPlayerCashCOC = fromPlayer;
		},
		// Dummy function, but you get the idea.  Obviously we need something more robust than this.
		getTwoLetterCountry(phoneCountryCode) {
			switch (phoneCountryCode) {
				case 1:
					return "US";
				case 52:
					return "MX";
				default:
					return "US";
			}
		},
		performNuveiCheckout() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.displayNuveiPurchaseWidget;

			/*
		Log levels for Nuvei's Simply Connect "checkout" API.
		Possible values:
			0 (default) – No logging
			1 – Plain text
			2 – Info
			3 – Warn
			4 – Error
			5 – Debug
			6 – Trace
		*/
			const nuveiLogLevel = 0;

			// About countries and payment methods:
			// The top-level country field is where we, the merchant, do business.  For Encore this will be MX, for instance.
			// The billingAddress.country is the player/user/consumer's country, and we base that on their phone number.
			// According to Andres Mauricio Clavijo Mantilla in our Teams chat on 2024-01-19, only credit cards are allowed for
			// foreigners to the business country, not "alternate payment methods" such as PayPal or OXXOPay.
			// The API fails quite severely if you try.  So we're using the "payment method whitelist" to make sure that
			// APMs aren't offered if the countries don't match.  (If no whitelist or blacklist are given, Nuvei shows all
			// methods we're set up to use.)
			const billingAddrCountry = this.getTwoLetterCountry(this.webPayOptions.userPhoneCountryCode);
			const payMethodWhitelist =
				billingAddrCountry === this.webPayOptions.nuvei.merchantTwoLetterCountryCode ? null : ["cc_card"];

			// Hard-coding the locale for this prototype.  In production, we should use whatever mechanism we're using for
			// front-end language elsewhere.
			let rfServer;
			let statusArea;
			let rfWebTransaction;
			let sessionId;
			let alreadyShowingResults;

			// if (document.getElementById("webpay-checkout-element").innerHTML !== "") {
			// 	checkout.destroy();
			// }

			// Tell Nuvei to create their UI inside one of our DOM elements.
			document.getElementById("webpay-checkout-element").innerHTML = "";
			const checkoutParams = {
				sessionToken: this.transaction.sessionId,
				env: this.webPayOptions.nuvei.environment,
				merchantSiteId: this.webPayOptions.nuvei.merchantSiteId,
				merchantId: this.webPayOptions.nuvei.merchantId,
				country: this.webPayOptions.nuvei.merchantTwoLetterCountryCode,
				currency: this.webPayOptions.cashOutCurrency.iso4217Code,
				locale: this.systemCurrencyTool.cultureCode,
				userId: this.transaction.transaction.userId,
				amount: this.transaction.transaction.fromPlayerCashCOC,
				pmWhitelist: payMethodWhitelist,
				renderTo: "#webpay-checkout-element",
				logLevel: nuveiLogLevel,
				billingAddress: {
					country: billingAddrCountry,
					email: this.webPayOptions.userEmailAddress,
					phone: this.webPayOptions.userPhoneNumber,
				},
				showResponseMessage: false,
				onResult: this.handleNuveiCheckoutResult,
				onPaymentEvent: this.handleNuveiPaymentEvent,
			};

			checkout(checkoutParams);
			this.eventBus.emit("showNuveiWidgit");
		},
		async handleNuveiCheckoutResult(result) {
			let response = await result;

			if (result?.result === "ERROR" && !result?.statusAdditionalInfo) {
				this.fromPlayerCashCOC = null;
				checkout.destroy();
				this.eventBus.emit("handelNuveiError", result);
				return;
			}

			if (response?.result) {
				this.nuveiResult = response;
				this.fromPlayerCashCOC = null;
				checkout.destroy();
				setTimeout(() => {
					this.showRfTranStatus();
				}, 1500);
			}
		},
		async handleNuveiPaymentEvent(result) {
			let response = await result;

			if (response?.result) {
				this.fromPlayerCashCOC = null;
				checkout.destroy();
				this.eventBus.emit("nuveiCheckoutResult", this.nuveiResult);
			}
		},
		async showRfTranStatus() {
			this.busyText = this.languageStrings.loadingBuyIns;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(
				`api/v1/webpay/transaction/${this.transaction.transaction.id}`,
				this.rabbitsfootHostUrl
			);
			let params = requestUrl.searchParams;

			request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();
				this.transactionResult = dataJson;

				if (this.transactionResult?.status === "RefusedByProvider") {
					this.fromPlayerCashCOC = null;
					checkout.destroy();
					this.eventBus.emit("handelNuveiError", this.transactionResult);
					return;
				}

				this.eventBus.emit("nuveiCheckoutResult", this.transactionResult);

				this.busyText = "";
				this.serverBusy = false;
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}

			headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			requestUrl = new URL("api/v1/funds/", this.rabbitsfootHostUrl);
			params = requestUrl.searchParams;

			if (this.transaction.transaction.casinoId) params.set("casinoId", this.transaction.transaction.casinoId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);
				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.playerFunds = dataJson;
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		updateFromPlayerCashCoC() {
			this.fromPlayerCashCOC = this.fromPlayerCashCOC;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-section {
	width: 100%;
	align-items: center;
}

.input-section span {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.input-section input {
	max-width: 90%;
	border-radius: 22px;
	text-align: center;
	font-size: 29px;
	color: #6305c9;
}

.buy-in-btn {
	/* background: linear-gradient(to right,blue, purple,#FA5F55); */
	/* background: white; */
	/* -webkit-text-fill-color: transparent; */
	/* color:#6305C9 !important; */
	background-clip: text;
	margin: 5px auto;
	width: 12vw;
	height: 4.5vh;
	font-weight: bolder;
	overflow-wrap: break-word;
}

button {
	display: block;
}

.column {
	flex: 1;
}

.row {
	display: flex;
}

.add-in-btn {
	display: flex;
	background-image: none;
}

div#fromPlayerCashCOC {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.currancy-symbol {
	padding: 0 5px;
	font-size: large;
}

/* #fromPlayerCashCOC input {
	width: 10em;
} */

#fromPlayerCashCOC span span {
	justify-content: end;
	width: 2em;
}

input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	cursor: pointer;
	background: #5d3fd3;
	border-radius: 50%;
	height: 20px;
	width: 20px;
}

input[type="range"]::-moz-range-thumb {
	background: #5d3fd3;
	height: 15px;
	width: 15px;
}

.button-container {
	background: white;
	border-radius: 15px;
	width: 12vw;
	margin-left: auto;
	margin-right: auto;
}

.button-container:hover {
	width: 12.7vw;
}

button.btn:disabled {
	background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3)) !important;
	color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
	border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3)) !important;
}

button.btn:disabled {
	background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3)) !important;
	color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
	border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3)) !important;
}

#select-container {
	display: flex;
	padding: 15px;
}

@media (max-width: 1065px) and (min-width: 769px) {
	.buy-in-btn {
		width: 17vw;
	}
	.button-container {
		width: 17vw;
	}
	.button-container:hover {
		width: 18vw;
	}
	
}

@media (max-width: 768px) {
	.input-section input {
		height: 45px;
		text-align: center;
		font-size: 35px;
		color: #46289b;
		max-width: 40vw;
	}
	.button-container {
		background: white;
		border-radius: 15px;
		width: 31vw;
	}

	.button-container:hover {
		width: 33vw;
	}

	.buy-in-btn {
		width: 31vw;
		padding: 5px;
	}
}
</style>
